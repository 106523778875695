import {required, email} from 'vuelidate/lib/validators'
import { mapGetters, mapMutations} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                title: '',
                name: '',
                description: '',
                phone:'',
                email:'',
                edrpou:'',
                cart:'',
                checking_account:'',
            }
        }
    },

    validations: {
        form: {
            title: {
                required
            },
            name: {
                required
            },
            phone:{
                required
            },
            email:{
                required,
                email
            },
            edrpou:{
                required,
            },
            cart:{
                required,
            },
            checking_account:{
                required,
            }

        }
    },
    computed: {
        ...mapGetters({
            provisioner: 'warehouse/provisioner',
        }),
    },
    watch:{
        provisioner(e){
            if(e){
                this.form = e;
            }else{
                this.form = {
                    title: '',
                    description: ''
                }
            }
        }
    },
    methods: {
        closePopup() {
            this.$emit('closePopup')
        },
        sendProvisioner() {
            if(this.provisioner){
                this.$emit('changeProvisioner', this.form)
            }else{
                this.$emit('sendProvisioner', this.form)
            }
        },
        ...mapMutations({
            changeShowProvisioner: 'warehouse/changeShowProvisioner'
        })
    },
    destroyed() {
        this.changeShowProvisioner(null);
    }
}